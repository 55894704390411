<template>
  <div>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">Trang chủ</li>
      <li class="breadcrumb-item">
        <a href="/admin/user/lists">Quản lý User</a>
      </li>

      <li class="breadcrumb-item">Thêm mới User</li>
    </ol>

    <form method="post" @submit.prevent="mySubmit" id="sendform">
      <input type="hidden" :value="from.id" name="id" />
      <div class="container">
        <ul class="nav nav-tabs" role="tablist">
          <li class="nav-item">
            <a class="nav-link active" data-toggle="tab" href="#home"
              >

            </a>
          </li>
        </ul>

        <!-- Tab panes -->
        <div class="tab-content">
          <div id="home" class="container tab-pane active">
            <br />

            <div class="col-sm-12">
              <div class="card">
                <div class="card-header">
                  <strong>Thông tin </strong>
                  <small>Form</small>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-10">
                      <div class="form-group">
                        <label for="name"
                          >Họ và Tên <span class="text-danger">*</span></label
                        >
                        <input
                          class="form-control"
                          placeholder=""
                          name="name"
                          type="text"
                          v-model="from.name"
                        />
                        <p class="text-danger">{{ error.name }}</p>
                      </div>
                    </div>

                    <div class="col-sm-10">
                      <div class="form-group">
                        <label for="name"
                          >Email <span class="text-danger">*</span></label
                        >
                        <input
                          class="form-control"
                          placeholder=""
                          name="email"
                          type="text"
                          v-model="from.email"
                        />
                        <p class="text-danger">{{ error.email }}</p>
                      </div>
                    </div>

                    <div class="col-sm-10">
                      <div class="form-group">
                        <label for="name"
                          >Số điện thoại
                          <span class="text-danger">*</span></label
                        >
                        <input
                          class="form-control"
                          placeholder=""
                          name="phone"
                          type="text"
                          v-model="from.phone"
                        />
                        <p class="text-danger">{{ error.phone }}</p>
                      </div>
                    </div>

                    <div class="col-sm-10">
                      <div class="form-group">
                        <label for="name">Địa chỉ </label>
                        <input
                          class="form-control"
                          placeholder=""
                          name="address"
                          type="text"
                          v-model="from.address"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="card">
                <div class="card-header">
                  <strong>Thông tin đăng nhập </strong>
                  <small>Form</small>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-10">
                      <div class="form-group">
                        <label for="name">Username </label>
                        <input
                          class="form-control"
                          placeholder=""
                          name="username"
                          type="text"
                          v-model="from.username"
                        />
                      </div>
                    </div>
                     <div class="col-sm-10">
                      <div class="form-group">
                        <label for="name"> Phân Quyền  </label>
                        <select class="form-control" name="roleId" v-model="from.roleId" v-if="my_roles!=null">
                          
                          <option v-for="(v,k) in my_roles" :key="k" :value="v.id">{{v.name}}</option>
                        
                        </select>
                      </div>
                    </div>

                    <div class="col-sm-10">
                      <div class="form-group">
                        <label for="name"> Quyền hạn  </label>
                        <select class="form-control" name="isRole" v-model="from.isRole">
                          <option value="1">System Admin</option>
                          <option value="2">Quản trị viên</option>
                   
                        </select>
                      </div>
                    </div>

                    <div class="col-sm-10">
                      <div class="form-group">
                        <label for="name">Mật khẩu </label>
                        <input
                          class="form-control"
                          placeholder=""
                          type="password"
                          @change="handlePass"
                          v-model="password"
                        />
                      </div>
                    </div>
                    <div class="col-sm-10">
                      <div class="form-group">
                        <label for="name">Nhập lại mật khẩu </label>
                        <input
                          class="form-control"
                          placeholder=""
                          type="password"
                          @change="handleRePass"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="card-footer"
            style="width: 90%; position: fixed; bottom: 0"
          >
            <button
              type="submit"
              class="btn btn-sm btn-primary"
              v-if="!is_load"
            >
              <i class="fad fa-save"></i> Save
            </button>
            <h5 v-else>Loading...</h5>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      from: {},
      id: this.$route.params.id,
      is_load: false,
      error: {
        name: "",
        email: "",
        phone: "",
        check_pass: "",
      },

      pass: "",
      re_pass: "",
      my_roles:null,
      
    };
  },
  
  mounted() {
    axios
      .get(window.my_api + "api/user/get-user-by-id?userId=" + this.id,{
                                 headers: {
                                   language: this.$i18n.locale
                                 }
                  })
      .then((res) => {
        if (res.status == 200) {
          this.from = res.data.data;
          this.password = res.data.data.password;
        } else {
          alert("Hệ thống không hoạt động, vui lòng liên hệ với Admin.");
        }
      });

      axios
      .get(window.my_api + "api/role/get-roles", {
        headers: {
          language: this.$i18n.locale,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          this.my_roles = res.data.data.filter(v=>v.language=="VN");
         
        } else {
          alert("Hệ thống không hoạt động, vui lòng liên hệ với Admin.");
        }
      });
  },

  methods: {
    alertError() {
      let tt = "";
      for (let i = 0; i < Object.values(this.error).length; i++) {
        if (Object.values(this.error)[i]) {
          tt =
            tt +
            "<ul style='text-align:left;'><li>" +
            Object.values(this.error)[i] +
            "</li></ul>";
        }
      }
      return tt;
    },
    handlePass(e) {
      this.pass = e.target.value;
    },
    handleRePass(e) {
      this.re_pass = e.target.value;
    },
    mySubmit: function () {
      let myForm = document.getElementById("sendform");
      let formData = new FormData(myForm);
      for (var pair of formData) {
        if (pair[0] == "name") {
          if (pair[1] == "") {
            this.error.name = "Vui lòng nhập tên.";
          } else {
            this.error.name = "";
          }
        }

        if (pair[0] == "email") {
          if (pair[1] == "") {
            this.error.email = "Vui lòng nhập email.";
          } else {
            this.error.email = "";
          }
        }
        if (pair[0] == "phone") {
          if (pair[1] == "") {
            this.error.phone = "Vui lòng nhập số điện thoại.";
          } else {
            this.error.phone = "";
          }
        }
      }
      if (
        this.error.name == "" &&
        this.error.email == "" &&
        this.error.phone == ""
      ) {
        if (
          this.pass !== this.re_pass ||
          (this.pass == "" && this.re_pass == "")
        ) {
          this.error.check_pass = "Mật khẩu nhập lại không đúng";
          this.$swal.fire({
            icon: "warning",
            html: this.alertError(),
          });
        } else {
          this.is_load = true;
          formData.append("password", this.pass);
          axios
            .post(window.my_api + "api/user/create-or-update-user", formData)
            .then((res) => {
              if (res.status == 200) {
                this.$swal.fire({
                  position: "top-end",
                  icon: "success",
                  title: "Cập nhật thành công.",
                  showConfirmButton: false,
                  timer: 1500,
                });
                 this.$router.go(this.$router.currentRoute);
                document.getElementById("sendform").reset();
              } else {
                alert("Cập nhật không thành công, vui lòng liên hệ admin");
              }
              this.is_load = false;
            });
        }
      } else {
        this.$swal.fire({
          icon: "warning",
          html: this.alertError(),
        });
      }
    },
  },
};
</script>